"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildShortRecipeQueryParameter = void 0;
require("url-search-params-polyfill");
function buildShortRecipeQueryParameter(recipe) {
    const searchParams = new URLSearchParams();
    if (recipe.name) {
        searchParams.set('n', recipe.name);
        recipe.ingredients
            .filter(it => it.name)
            .forEach(ingredient => searchParams.append(ingredient.name, ingredient.quantity || ''));
    }
    return searchParams.toString().replace('=&', '&')
        .replace(/=$/g, '');
}
exports.buildShortRecipeQueryParameter = buildShortRecipeQueryParameter;
