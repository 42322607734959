"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decode = exports.encode = void 0;
const base64url_1 = require("base64url");
const fflate = require("fflate");
const buffer_1 = require("buffer");
const toDto = (recipe) => ({
    n: recipe.name,
    i: recipe.ingredients
        .map(it => ({ [it.name]: (it.quantity ? it.quantity : '') }))
        .reduce((a, b) => (Object.assign(Object.assign({}, a), b)), {})
});
const fromDto = (recipe) => {
    return {
        name: recipe.n,
        ingredients: Object.entries(recipe.i)
            .map(([name, quantity]) => quantity ? {
            name,
            quantity
        } : { name })
    };
};
/**
 * Encodes a Recipe into an encoded Recipe
 * @param recipe Recipe to recipeEncoding.
 */
const encode = (recipe) => (0, base64url_1.default)(buffer_1.Buffer.from(fflate.deflateSync(fflate.strToU8(JSON.stringify(toDto(recipe))))));
exports.encode = encode;
/**
 * Decodes a an encoded Recipe
 * @param recipe Recipe to decode.
 */
const decode = (recipe) => fromDto(JSON.parse(fflate.strFromU8(fflate.inflateSync(base64url_1.default.toBuffer(recipe)))));
exports.decode = decode;
